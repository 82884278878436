.people-box {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, auto));
    justify-content: flex-start;
}

.people-box div {
    padding: 10px;
}

.people-box figcaption {
    text-align: center;
}

.logo-box {
    display: flex;
    flex-direction: row;
}

.logo-box div {
    padding: 10px;
}

.vide-target {
    z-index: -1;
    position: fixed;
    width: 100%;
    height: 1000px;
}

.site-header {
    background-color: white;
}

.site-footer {
    background-color: white;
}

.wrapper {
    background-color: white;
    border-radius: 20px;
}
